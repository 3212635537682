<template>
  <div>
    <event-add></event-add>
  </div>
</template>

<script>
import EventAdd from './EventAdd.vue';

export default {
  components: {
    EventAdd,
  },

  data() {
    return {};
  },
};
</script>
